<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Płatność</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
            <v-row>
                <v-col cols="12" md="12" sm="12" >
                    <v-text-field
                        v-model="amount"
                        @input="onAmountChaned"
                        label="Kwota którą chesz wpłacić"
                        required
                        dense
                        hide-details
                        outlined
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>

                <v-col cols="12" md="12" sm="12"  class="mb-2" v-if="zenEnabled">
                    <span class="black--text">Szybka płatność przelewem natychmiastowym lub kartą:</span><br/>
                    <v-alert class="mt-2 px-3 py-1" outlined type="primary" dense border="left" style=font-size:13px;>
                        <v-img
                            style="cursor:pointer;"
                            @click="zen"
                            lazy-src="../../assets/payments-methods.png"
                            contain
                            width="550"
                            src="../../assets/payments-methods.png"
                        ></v-img>
                    </v-alert>
                </v-col>
                
                <v-col cols="12" md="6" sm="12" >
                    <span class="black--text">Przelew tradycyjny:</span><br/>
                    <v-alert class=" mt-2 px-3 py-1" outlined type="primary" dense border="left" style=font-size:13px;>
                        Zapłać przelewem tradycyjnym na podany poniżej numer konta:<br/><br/>
                        Amelia-Wedding.pl Szymon Jędrzejczak<br/>
                        <strong>56 1140 2004 0000 3202 6681 8030</strong><br/>
                        <span class="caption">BIC/SWIFT: BREXPLPWMBK</span><br/><br/>
                        W tytule przelewu prosimy podać numer zamówienia: <b>#{{ orderNum.toUpperCase() }}</b>
                    </v-alert>
                </v-col>
                <v-col cols="12" md="6" sm="12"  class="mb-2" v-if="paynowEnabled">
                    <span class="black--text">Szybki przelew Paynow:</span><br/>
                    <v-alert class="mt-2 px-3 py-1" outlined type="primary" dense border="left" style=font-size:13px; height="76px">
                        <v-img
                            style="cursor:pointer;"
                            @click="paynow"
                            lazy-src="../../assets/paynow.png"
                            contain
                            width="200"
                            src="../../assets/paynow.png"
                        ></v-img>
                    </v-alert>
                </v-col>

            </v-row>
            <v-divider/>
        </v-card-text>
        
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary darken-1" text @click.native="agree">Potwierdz</v-btn> -->
            <v-btn color="grey" text @click.native="cancel">Cofnij</v-btn>
        </v-card-actions>

        <v-overlay :value="loading">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    
    </v-card>
</template>

<script>
import Api from '../../CustomerApi';
export default {
    props: {
        toPay: Number,
        orderNum: Text
    },
    data() {
        return {
            loadingChannels: false,
            loading: false,
            paynowEnabled: false,
            zenEnabled: true,
            amount: null,
            zenChannels: []
        }
    },

    created() {
        this.amount = this.toPay+"";

        if (process.env.VUE_APP_PAYMENT_OPERATOR == 'zen') {
            this.zenEnabled = true;
            this.paynowEnabled = false;
        } else {
            this.zenEnabled = false;
            this.paynowEnabled = true;
        }

        let query = this.$router.currentRoute.query;
        if (query.zen) {
            this.zenEnabled = true;
        }

    },

    methods: {
        agree() {
            this.$emit('close', true);
        },

        cancel() {
            this.$emit('close', null);
        },

        onAmountChaned() {
        },


        async zen() {
            let value = parseFloat(this.amount.replace(',', '.'));
            if (!value) return;
            let data = { amount:value, backUrl: window.location.href };
            this.loading = true;
            try {
                let res = await Api.payments.zen(data);
                if (!res || !res.redirectUrl) {
                    return this.$toast.error("Nie udało się utworzyć płatności");
                }
                window.location = res.redirectUrl;
            } catch (error) {
                return this.$toast.error("Nie udało się utworzyć płatności");
            }
            this.loading = false;
        },

        async paynow() {
            let value = parseFloat(this.amount.replace(',', '.'));
            let data = {amount:value, backUrl:window.location.href}
            try {
                let redirect = await Api.payments.paynow(data);
                window.location.replace(redirect.redirectUrl);
            } catch (error) {
                console.log("errro")
            }
        }
    },
}
</script>