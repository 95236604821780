import axios from 'axios';
import config from './config.js';
import EventEmitter from 'events';

class Api {
    constructor() {
        this.baseUrl = config.apiProtocol + '://' + config.apiUrl + '/';
        this.token = null;
        this.events = new EventEmitter();
        
        this.axiosConfig = {
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        };
        
        this.getFileUrl = (key, name) => {
            return this.baseUrl + 'files/' + key + '/' + name;
        }

        this.getConversionCSVUrl = () => {
            return this.baseUrl + 'ext-services/googleads/conversion.csv';
        }

        this.getPrintOrderUrl = (orderId) => {
            return this.baseUrl + 'admin/print/order/' + orderId + '?token='+this.token;
        }

        this.getInpostLabel= (shipmentId) =>  {
            return this.baseUrl + 'admin/shipment/label/' + shipmentId + '?token='+this.token;
        }

        this.settings = {
            get:  (service) => this.callCommonEndpoint('admin/configuration/'+service, 'get'),
            save: (service, data) => this.callCommonEndpoint('admin/configuration/'+service, 'put', data),
        }

        this.features = {
            add: (data) => { return this.callCommonEndpoint('admin/feature', 'post', data) },
            edit: (id,data) => { return this.callCommonEndpoint('admin/feature/'+id, 'put', data) },
            delete: (id) => { return this.callCommonEndpoint('admin/feature/'+id, 'delete') },
            search: (data) => { return this.callCommonEndpoint('admin/feature/search', 'post',  data) },
            addSet: (data) => {return this.callCommonEndpoint('admin/feature-set', 'post', data)},
            editSet: (id,data) => {return this.callCommonEndpoint('admin/feature-set/'+id, 'put', data)},
            getAllSet: () => {return this.callCommonEndpoint('admin/feature-set/all')},
            deleteSet: (id) => { return this.callCommonEndpoint('admin/feature-set/'+id, 'delete') },
        }

        this.voucher = {
            create: (data) => { return this.callCommonEndpoint('admin/voucher', 'post',  data) },
            save: (id, data) => { return this.callCommonEndpoint('admin/voucher/'+id, 'put',  data) },
            search: (data) => { return this.callCommonEndpoint('admin/voucher/search', 'post',  data) },
        }

        this.notes = {
            search: (data) => { return this.callCommonEndpoint('admin/notes/search', 'post',  data) },
        }

        this.order = {
            addFile: (orderId, data) => { return this.callCommonEndpoint('admin/order/'+orderId+'/file', 'put', data) },
            search: (data) => { return this.callCommonEndpoint('admin/order/search', 'post',  data) },

            

            get: (id) => {return this.callCommonEndpoint('admin/order/'+id)},
            update: (id, data) => {return this.callCommonEndpoint('admin/order/'+id, 'put', data)},
            updateVariant:(cartPosId, variantId, data) => { return this.callCommonEndpoint('admin/order-content/'+cartPosId+'/variant/'+variantId, 'put', data) },
            
            updateContent: (cartPosId, data) => { return this.callCommonEndpoint('admin/order-content/'+cartPosId, 'put', data) },

            updatePrice: (cartPosId, data) => { return this.callCommonEndpoint('admin/order-position/'+cartPosId+"/price", 'put', data) },

            getContent: (id) => {return this.callCommonEndpoint('admin/order/'+id+'/contents')},
            getOrderPos: (id) => {return this.callCommonEndpoint('admin/order-position/'+id)},
            getNotes: (id) => {return this.callCommonEndpoint('admin/order/'+id+'/notes')},
            addNote: (id, content) => {return this.callCommonEndpoint('admin/order/'+id+'/notes', 'put', {content:content})},
            removeNote: (id) => {return this.callCommonEndpoint('admin/order/notes/'+id, 'delete', {})},
            addProduct: (productId, orderId, source) => { return this.callCommonEndpoint('admin/order/'+orderId+'/addProduct', 'post', { productId, source }) },
            changeStatus: (id, status) => { return this.callCommonEndpoint(`admin/order/${id}/status`, 'post', {status}) },
            removeContent: (cartPosId) => { return this.callCommonEndpoint('admin/order-content/'+cartPosId, 'delete') },

            getLogs: (orderId) => { return this.callCommonEndpoint('admin/order/'+orderId+'/history') },

            putProject: (orderPositionId, variantId, data) => { return this.callCommonEndpoint('admin/order-content/'+orderPositionId+'/project/'+variantId, 'put', data) },

            addCustomPos: (orderPositionId, data) => { return this.callCommonEndpoint('admin/order-content/'+orderPositionId+'/variant', 'post', data) },
            removeCartPos: (orderPositionId, variantId) => { return this.callCommonEndpoint('admin/order-content/'+orderPositionId+'/variant/'+variantId, 'delete') },

            setDelivery: (deliveryTypeId, data, orderId) => { return this.callCommonEndpoint('admin/order/'+orderId+'/delivery', 'put', {deliveryTypeId, data}) },

            enableExpress: (orderId, isExpress) => { return this.callCommonEndpoint('admin/order/'+orderId+'/express', 'put',  { isExpress: isExpress }) },

            getPrestashopBindedOrder: (orderId) => { return this.callCommonEndpoint('admin/order/'+orderId+'/prestashop-order') },
            createPrestashopBindedOrder: (orderId) => { return this.callCommonEndpoint('admin/order/'+orderId+'/prestashop-order', 'post', {}) },
        }

        this.shipment = {
            getByOrderId: (orderId) => { return this.callCommonEndpoint('admin/shipment/byOrder/'+orderId) },
            add: (data) => { return this.callCommonEndpoint('admin/shipment', 'post', data) },
            searchPoints: (name) => { return this.callCommonEndpoint('admin/shipment/search-points/'+name) },
        }

       

        this.payments = {
            get: (orderId) => { return this.callCommonEndpoint('admin/order/'+orderId+'/payments') },
            add: (orderId, data) => { return this.callCommonEndpoint('admin/order/'+orderId+'/payments', 'post', data) },
            addVoucher: (orderId, data) => { return this.callCommonEndpoint('admin/order/'+orderId+'/discount', 'post', data) },
            removeVoucher: (orderId, voucherId) => { return this.callCommonEndpoint('admin/order/'+orderId+'/discount/'+voucherId, 'delete') },
            
        }

        this.delivery = {
            search: (data) => { return this.callCommonEndpoint('admin/delivery-types/search', 'post',  data) },
            add: (data) => { return this.callCommonEndpoint('admin/delivery-types/add', 'post',  data) },
            saveOrder: (idsInOrder) => { return this.callCommonEndpoint('admin/delivery-types/order', 'put',  { orderedList: idsInOrder }) },
            update: (deliveryId, data) => { return this.callCommonEndpoint('admin/delivery-types/update/'+deliveryId, 'put',  data) },
            remove: (deliveryId) => { return this.callCommonEndpoint('admin/delivery-types/'+deliveryId, 'delete') },
        }

        this.user = {
            logIn: (email, pass) => { return this.callCommonEndpoint('admin/auth/login', 'post', { user: email, pass: pass }) },
            verify: (token) => { return this.callCommonEndpoint('admin/auth/verify', 'post',  { token: token }) },

            get: () => { return this.callCommonEndpoint('admin/users') },
            getMe: () => { return this.callCommonEndpoint('admin/me') },
            update: (userId, data) => { return this.callCommonEndpoint('admin/users/'+userId, 'put',  data) },
            add: (data) => { return this.callCommonEndpoint('admin/users', 'post',  data) },
            remove: (userId) => { return this.callCommonEndpoint('admin/users/'+userId, 'delete') },
        }

        this.designer = {
            getKey: () => { return this.callCommonEndpoint('admin/designer/user-key') },
            saveKey: (key) => { return this.callCommonEndpoint('admin/designer/user-key', 'post',  {key:key}) },
            getProjects: (orderId) => { return this.callCommonEndpoint('admin/designer/projects/byOrder/'+orderId) },
            addProject: (data) => { return this.callCommonEndpoint('admin/designer/projects', 'post', data) },
            duplicateProject: (project, data) => { return this.callCommonEndpoint('admin/designer/projects/'+project._id+'/duplicate', 'post', data) },
            searchProduct: (query) => { return this.callCommonEndpoint('admin/designer/products/search', 'post', {query:query}) },
            getProductTemplate: (designerProductId) => { return this.callCommonEndpoint('admin/designer/template/byProduct/'+designerProductId) },
        }

        this.products = {
            search: (data) => { return this.callCommonEndpoint('admin/products/search', 'post', data) },
            getPrestaImageUrl: (prestaProdId, imageId) => { return `${this.baseUrl}admin/presta/product-image/${prestaProdId}/${imageId}?token=${this.token}` }
        }

        this.customer = {
            getNotes: (email) => {return this.callCommonEndpoint('admin/customer/'+email+'/notes')},
            addNote: (email, content) => {return this.callCommonEndpoint('admin/customer/'+email+'/notes', 'post', {content:content})},
        }

        this.statistics = {
            getDesignerAssignments: () => { return this.callCommonEndpoint('admin/stats/designerAssignments') },
            getOrdersQtyPerDays:   (data) => this.callCommonEndpoint('admin/stats/getOrderQtyPerDays',  'post', data),
            getComapreOrdersStats: (data) => this.callCommonEndpoint('admin/stats/getCompareOrdersStats',  'post', data),
            generateProductsStats: (data) => this.callCommonEndpoint('admin/stats/generateProductsStats',  'post', data)
        }

        this.wFirma = {
            getDocuments: (orderId) => { return this.callCommonEndpoint('admin/invoices/byOrder/'+orderId) },
            addDocument:  (orderId, data) => { return this.callCommonEndpoint('admin/invoices/forOrder/'+orderId,  'post', data) },
        }

        this.sms = {
            getPhoneHistory: (phoneNo) => this.callCommonEndpoint('admin/serwersms/history/' + phoneNo),
            send: (data) => this.callCommonEndpoint('admin/serwersms/send', 'post', data),
        }

        this.orderState = {
            search: (data) => this.callCommonEndpoint('admin/order-state/search', 'post', data),
            create: (name) => this.callCommonEndpoint('admin/order-state',  'post', { name }),
            save:   (id, data) => this.callCommonEndpoint('admin/order-state/'+id, 'put', data),
            settings: {
                get:  () => this.callCommonEndpoint('admin/order-state/settings', 'get'),
                save: (conf) => this.callCommonEndpoint('admin/order-state/settings', 'post', conf),
            }
        }

        this.extServices = {
            search: (data) => { return this.callCommonEndpoint('admin/ext-service-access/search', 'post', data) },
            create:  (name) => { return this.callCommonEndpoint('admin/ext-service-access',  'post', { name }) },
        }

        this.production = {
            user: {
                getAll: () => { return this.callCommonEndpoint('admin/production/users', 'get') },
                add: (userId) => { return this.callCommonEndpoint('admin/production/create-user-access', 'post', {userId}) },
                changePIN: (userId, pin) => { return this.callCommonEndpoint(`admin/production/users/${userId}/changePIN`, 'put', {pin}) },
            },
            device: {
                generatePairCode: () => { return this.callCommonEndpoint('admin/production/device/generate-pair-code', 'post', {}) },
                search: (data) => { return this.callCommonEndpoint('admin/production/device/search', 'post', data) },
                update: (id, data) => { return this.callCommonEndpoint('admin/production/device/'+id, 'put', data) },
                remove: (id) => { return this.callCommonEndpoint('admin/production/device/'+id, 'delete') },
            },
            workplace: {
                add: (name) => { return this.callCommonEndpoint('admin/production/workplace', 'post', {name}) },
                save: (id, data) => { return this.callCommonEndpoint('admin/production/workplace/'+id, 'put', data) },
                remove: (id) => { return this.callCommonEndpoint('admin/production/workplace/'+id, 'delete') },
                addShelve: (workplaceId, code) => { return this.callCommonEndpoint(`admin/production/workplace/${workplaceId}/shelve`, 'post', {code}) },
                search: (data) => { return this.callCommonEndpoint('admin/production/workplace/search', 'post', data) },
                getAvailableTasks: (workplaceId, sortBy = 'priority', sortDir = -1) => { return this.callCommonEndpoint(`admin/production/workplace/${workplaceId}/tasks?sort[${sortBy}]=${sortDir}`, 'get') },
            },
            task: {
                search: (data) => { return this.callCommonEndpoint('admin/production/task/search', 'post', data) },
                setPriority: (taskId, priority) => { return this.callCommonEndpoint(`admin/production/task/${taskId}/priority`, 'put', {priority}) },
            },
            worklogs: {
                search: (data) => { return this.callCommonEndpoint('admin/production/worklog/search', 'post', data) },
            }
        }

        this.marketing = {
            receivers: {
                search: (data) => { return this.callCommonEndpoint('admin/marketing/receivers/search', 'post', data) },
                save: (id, data) => { return this.callCommonEndpoint('admin/marketing/receivers/'+id, 'put', data) },
                create: (name) => { return this.callCommonEndpoint('admin/marketing/receivers', 'post', { name }) },
            },

            campaings: {
                search: (data) => { return this.callCommonEndpoint('admin/marketing/campaings/search', 'post', data) },
                save: (id, data) => { return this.callCommonEndpoint('admin/marketing/campaings/'+id, 'put', data) },
                create: (name) => { return this.callCommonEndpoint('admin/marketing/campaings', 'post', { name }) },
                testSend: (id, email, name) => { return this.callCommonEndpoint('admin/marketing/campaings/'+id+'/testSend', 'post', { email, name, force: true }) },
                getTemplatePlaceholders: () => { return this.callCommonEndpoint('admin/marketing/getTemplatePlaceholders', 'get') },
            },

            history: {
                search: (data) => { return this.callCommonEndpoint('admin/marketing/history/search', 'post', data) },
            },

            sender: {
                getRaports: () => { return this.callCommonEndpoint('admin/marketing/sender/raports', 'get') },
            }
        }

        this.notificationEmiters = {
            create: (name) => this.callCommonEndpoint('admin/notifications-emiters', 'post', { name }),
            save: (id, data) => this.callCommonEndpoint('admin/notifications-emiters/'+id, 'put', data),
            search: (data) => this.callCommonEndpoint('admin/notifications-emiters/search', 'post', data),
            getAvailableEvents: () => this.callCommonEndpoint('admin/notifications-emiters/available-events', 'get'),
        }
        
    }

    setToken(token) {
        this.token = token;
        this.axiosConfig.headers.Authorization = 'Bearer ' + token;
    }

    callCommonEndpoint(endpoint, method, data) {
        if (!method) method = 'get';
        return axios({
            method: method,
            url: this.baseUrl + endpoint,
            headers: this.axiosConfig.headers,
            data: JSON.stringify(data)
        }).then((response) => { return response.data; });
    }
}

Api.prototype.uploadFile = function(file, progresCallback) {
    const promise = axios.post(this.baseUrl + 'admin/files', file, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: this.axiosConfig.headers.Authorization
        },
        onUploadProgress: progresCallback
    })
        .then((response) => {
            return response.data;
        })
        .catch(error => {
            throw error.response;
        });

    return promise;
};

Api.prototype.searchOrdersWithTotal = function(data) {
    const promise = axios.post(this.baseUrl + 'admin/order/search', JSON.stringify(data), this.axiosConfig)
        .then((response) => {
            const arrayRes = {};
            arrayRes.orders = response.data;
            arrayRes.total = parseInt(response.headers['x-total-found']);
            return arrayRes;
        });

    return promise;
};

let instance =  new Api();

export default instance;
