var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-5 mt-3 left-bordered-primary",attrs:{"elevation":"1"}},[_c('div',{staticClass:"ml-5 pt-2 font-weight-light"},[_vm._v("Logi wszystki zmian")]),_c('v-divider',{staticClass:"mt-2"}),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headersLogs,"items":_vm.sortLogs,"loading":_vm.loading,"loading-text":"Loading... Please wait","sort-desc":[false, true],"hide-default-header":true,"items-per-page":5},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [(_vm.logsCodes[item.code])?_c('p',{staticClass:"text-caption mb-0"},[_vm._v(_vm._s(_vm.logsCodes[item.code].name))]):_c('span',[_vm._v("Brak kodu !")]),(item.code == 'order.delivery-update')?_c('span',{staticClass:"ml-2 font-weight-bold"},[_vm._v(_vm._s(_vm.getDeliveryName(item.params.deliveryTypeId)))]):_vm._e(),(item.code == 'order.status-update' && _vm.orderStatuses[item.params.status])?_c('span',{staticClass:"ml-2 font-weight-bold"},[_vm._v(_vm._s(_vm.orderStatuses[item.params.status].name))]):_vm._e(),(item.code == 'order-pos.price-update' && item.params.price)?_c('span',{staticClass:"ml-2 font-weight-bold"},[_vm._v("na "+_vm._s(_vm._f("money")(item.params.price))+"/szt")]):_vm._e()]}},{key:"item.params",fn:function(ref){
var item = ref.item;
return [(item.params.id)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getProductsName(item.params.id)))]):_vm._e(),(item.params.orderPositionId)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getProductsName(item.params.orderPositionId)))]):_vm._e(),(item.code == 'order-pos.update')?_c('span',[(item.params.details)?_c('span',[_c('p',{staticClass:"grey--text text-caption mb-0"},[_vm._v("Zmieniono:")]),_vm._l((item.params.details),function(details,i){return _c('p',{key:i,staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getFeatureName(i))+" "),(details)?_c('span',[_vm._v(" na "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(details))])]):_c('span',[_vm._v(" - "),_c('span',{staticClass:"red--text"},[_vm._v("brak podanego")])])])})],2):_vm._e(),(item.params.paidDetails)?_c('span',[_c('p',{staticClass:"grey--text text-caption mb-0"},[_vm._v("Zmieniono płatny atrybut:")]),_vm._l((item.params.paidDetails),function(details,i){return _c('p',{key:i,staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.getFeatureName(i))+" "),(details)?_c('span',[_vm._v(" na "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(details))])]):_c('span',[_vm._v(" - "),_c('span',{staticClass:"red--text"},[_vm._v("brak podanego")])])])})],2):_vm._e()]):_vm._e(),(item.code == 'order-variant.update')?_c('span',[_c('p',{staticClass:"grey--text text-caption mb-0"},[_vm._v("Zmieniono:")]),(item.params.name)?_c('p',{staticClass:"mb-0"},[_vm._v("Nazwę na "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.params.name))])]):_vm._e(),(item.params.guestList)?_c('p',{staticClass:"mb-0"},[_vm._v("Listę gości")]):_vm._e(),(item.params.qty)?_c('p',{staticClass:"mb-0"},[_vm._v("Ilość na "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.params.qty))])]):_vm._e(),(item.params.content)?_c('p',{staticClass:"mb-0"},[_vm._v("Treści")]):_vm._e()]):_vm._e()]}},{key:"item.at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"grey--text text-caption"},[_vm._v(_vm._s(_vm._f("moment")(item.at,"DD/MM/YY HH:mm")))])]}},{key:"item.by",fn:function(ref){
var item = ref.item;
return [(item.by == 'user')?_c('span',{staticClass:"text-caption"},[_vm._v("Dodał:"),_c('br'),_vm._v(" Pracownik "),(item.user && _vm.from == 'admin')?_c('span',{staticClass:"gray--text"},[_vm._v("("+_vm._s(_vm.EmployeeManager.getName(item.user))+")")]):_vm._e()]):_c('span',{staticClass:"text-caption"},[_vm._v("Dodał:"),_c('br'),_vm._v(" Klient")])]}}])}),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Anuluj")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }