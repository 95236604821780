<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Uzupełnij listę gości </v-toolbar-title>
        </v-toolbar>
        <div class="borderd-box primary--text text-caption mx-4 my-2 px-2">
            Prosimy podawać listę gości w jednej linii np.<br/>
            Sz.P. Ewelinę i Mariusza Nowak<br/>
            Sz.P. Martę i Adama Kowalskich itd.<br/>
            <b>Niezapomnij aby srawdzić czy imiona i nazwiska są poprawnie odmienione. </b>
        </div>
        <v-chip class="ml-4" small color="primary" text-color="white" >
            Ilość linii: <b>{{ lineCount }}</b>
        </v-chip>
        <span class=" ml-4 primary--text text-caption"><b>Po wklejeniu listy gości system automatycznie przeliczy liczbę zaproszeń</b></span>
        <v-card-text class="px-4 mt-2">
            <v-textarea :counter="1" v-on:keyup="handleKeyUp" @click="onTxtKeyDown" outlined height="250px" v-model="list" label="Lista gości" hide-details></v-textarea>
            <span class="text-caption grey--text">Obecnie jesteś na linii nr.: {{cursorLine}}</span>
            <div class="red-border px-2 py-1 mt-1" v-if="isError">
                <span class="red--text">Brak zwrotu <b>Sz.P.</b> w liniach:
                    <span class="ml-1" v-for="e in errors" :key=e.line><b>{{e.line}}</b>,</span>
                </span>
                <!-- <p class="mb-0 text-caption" v-for="e in errors" :key=e.line>
                    <span class="red--text">{{e.text}} <b>{{e.val}}</b> Linia: <b>{{e.line}}</b></span>
                </p> -->
            </div>
            <div class="yellow-border amber--text px-2 py-1 mt-1  text-caption" v-if="errorSpecificLetter">
                Czy jesteś pewien że lista jest dobrze odmieniona? (np. Sz.P. Ann<b class="amber--text">ę</b> i Marka Nowak).<br/>
                W któreś linii może brakować odmiany.
            </div>
        </v-card-text>
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click.native="agree">Zapisz</v-btn>
            <v-btn color="grey" text @click.native="cancel">Anuluj</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        text: String,
        cartPosName: String
    },
    components: {
    },
    data() {
        return {
            list: "",
            loading: false,
            isInvitation:false,
            isError:false,
            errorSpecificLetter:false,
            errors: [],
            cursorLine: 0
        }
    },
    mounted: function() {
    },
    destroyed: function() {
    },
    computed: {
        lineCount: function() {
            // Return number of lines using regex if not empty
            return this.list.length ? this.list.split(/\r\n|\r|\n/).filter(el => el.trim().length).length : 0;
        },
        
    },
    created() {
        if(this.text && this.text.length > 0)   this.list = JSON.parse(JSON.stringify(this.text));
        let pos = JSON.parse(JSON.stringify(this.cartPosName));
        let p = pos.toLowerCase();
        this.isInvitation = p.includes("zaprosz");
    },

    methods: {
        handleKeyUp(e) {
            this.onTxtKeyDown(e);
            // if (e.keyCode === 13) {
            this.isError = false;
            this.errorSpecificLetter = false;
            this.errors.splice(0);
            let startWithSpecificLettter = false;

            while (this.list.indexOf('\n\n') > -1) this.list = this.list.replace('\n\n', '\n');
                
            let splitedLines = this.list.split(/\r\n|\r|\n/);
            for (let i = 0; i < splitedLines.length; i++) {
                const line = splitedLines[i].trim();
                if(line.length == 0) continue;
                let isSZP =  line.startsWith("Sz.P.");

                if(!isSZP) {
                    this.errors.push({text:'Brak na początku lini zwrotu', val:"Sz.P.", line:(i+1)});
                    this.isError = true;
                }

                if (!startWithSpecificLettter) {
                    let arrString = line.split(" ")
                    if(arrString[1]) startWithSpecificLettter = arrString[1].endsWith("ę");
                }

            }

            if(this.isInvitation && !startWithSpecificLettter){
                this.errorSpecificLetter = true;
            }
            // }     
        },

        onTxtKeyDown(event) {
            let textarea = event.target;
            let line = textarea.value.substr(0, textarea.selectionStart).split("\n").length;
            this.cursorLine = line;
        },

        agree() {
            this.$emit('close', this.list);
        },

        cancel() {
            this.$emit('close', null);
        }
    },
}
</script>
<style lang="scss" scoped>

.borderd-box {
    border:thin solid #834966;
}
.red-border {
    border:thin solid red;
}
.yellow-border {
    border:thin solid #ffc107;
}
</style>