var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"primary","flat":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Historia płatności")])],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.payments,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,"DD/MM/YY HH:mm")))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.value)+"zł")])]}},{key:"item.createdBy",fn:function(ref){
var item = ref.item;
return [(item.createdBy == 'user')?_c('span',{staticClass:"text-caption grey--text"},[_vm._v("(dodał: Pracownik)")]):_c('span',{staticClass:"text-caption grey--text"},[_vm._v("(dodał: Użytkownik)")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":"blue","x-small":""}},[_vm._v(_vm._s(item.status))])]}}])}),_c('v-divider')],1),_c('v-card-actions',{staticClass:"pt-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v("Cofnij")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }