<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Uzupełnij treści swojego produktu</v-toolbar-title>
        </v-toolbar>
        <v-alert
            class="mx-4 mt-2"
            v-if="disabled"
            border="left"
            color="red"
            dense
            outlined
            type="error"
        >
            Zmiana treści, spowoduje cofnięcie statusu Towojego zamówienia do <strong>"projektowanie"</strong>. Może to opóźnić szacowany czas wysyłki zamówienia.
            <br/><v-btn class="mt-1" color="primary darken-1" outlined small @click="disabled = false">Ok, rozumiem</v-btn>
        </v-alert>
        <v-card-text class="pa-4">
            <v-textarea
                :disabled=disabled
                outlined
                height="250px"
                v-model="eText"
                label="Treści w projekcie"
                value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
            ></v-textarea>
        </v-card-text>
       
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click.native="agree">Zapisz</v-btn>
            <v-btn color="grey" text @click.native="cancel">Anuluj</v-btn>
        </v-card-actions>
        
    </v-card>
</template>

<script>
export default {
    props: {
        text: String,
        showWarning: Boolean
    },
    data() {
        return {
            eText: "",
            loading: false,
            disabled:false
        }
    },

    created() {
        this.eText = this.text || "";
        if (this.showWarning) this.disabled = true
    },

    methods: {
        agree() {
            this.$emit('close', this.eText);
        },

        cancel() {
            this.$emit('close', null);
        }
    },
}
</script>